import './CText.css'

const CText = ({display_text, onClick}) => {
    return  <div onClick={onClick}
                    >
                <p className="CText_content">
            
                    {display_text}
                
                </p>
            </div>
}

export default CText;