import { useEffect, useState } from 'react';
import './ReportPage.css'


import { GPT_Report } from '../InputsPage/InputsPage';
import Button from '../../components/reusable/Button/Button';
import { Link } from 'react-router-dom';

const ReportPage = ({predictedPrice, loading, setLoading}) => {
    useEffect(()=>{
        
        if(predictedPrice)
        {
            setLoading(false)
        }
    }, [predictedPrice])

    return  (loading) ?
            <div className='report_page_loading_page_container_outer'>
                <div className='report_page_loading_page_container_inner'>
                    <div className='report_page_loading rotating'> 
                    </div>
                    <div>
                        <h1> Generating Report </h1>
                    </div>
                </div>
            </div>
                :
            <div className='report_page_report_container'>
                <div className='report_page_predicted_price_display'>
                    <div className='report_page_number_display'>
                        <p>$</p>
                        <h1> { Math.round((predictedPrice+Number.EPSILON)*100)/100} </h1>
                        <p>m</p>
                    </div>

                    <div>
                        <i> Predicted Price </i>
                    </div>
                </div>

                <div className='report_page_text'>
                    <p>
                    {GPT_Report}
                    </p>
                </div>

                <div className='back_to_inputs_button'>
                   <Link to="/inputs"> <Button displayText={'Back'}/>
                   </Link>
                </div>
            </div>  
    }

export default ReportPage;
