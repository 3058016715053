import React, {useState, useEffect, useRef, Fragment} from 'react'

import './INPUT_CUSTOM.css'

const INPUT_CUSTOM = ({URL, DropDown, Options, change_function, setErr, placeholder, questionNumber, setChange}) => 
{
    console.log(setErr)
    /**This Reference is used to adjust the value of the Inputs bar. */
    const Input_Bar = useRef(null);

    const [options_visible, set_options_visible] = useState(false)
    const [display_items, set_display_items] = useState([])
    const [initial_display_items, set_initial_display_items] = useState([])
    
    const [selected_item, set_selected_item] = useState('')
    const [item_clicked, set_item_clicked] = useState(false)
 
    useEffect(()=>{
        if(DropDown)
        {            
            let body = undefined;
            
            if(Options.method=='POST')
            {
                body = JSON.stringify(Options.body)
            }

            fetch(URL,{
                method: Options.method,
                body: body,
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((res)=>res.json())
            .then((res)=>{  
                set_initial_display_items(res)
                set_display_items(res)
                console.log(res)
                return res
            })
            .catch(err=>{
                setErr(true)
                console.log(err)
            })
        }

        Input_Bar.current.value = ''

        return ()=>{
            set_initial_display_items([])
            set_display_items([])
        }
    },[questionNumber])
    

    //Function that takes in a string value and an array 
    //Returns an array with values that include the string
    const filteration = (value, initial_array) => {
        let new_array = []

        initial_array.forEach((array_val)=>{
            
            let lowArrayVal = array_val.toLowerCase().replace(/ /g, "");
            let lowValue = value.toLowerCase().replace(/ /g, "");
            
            if (lowArrayVal.includes(lowValue))
            {
                new_array.push(array_val)
            }
        })
        return new_array
    }                        


    const list_display = (items)=>{  
            
            let elts = []
            for(let i=0;i<items.length;i++)
                    {
                elts[i] = <div className='individual_items'>
                                <h1 onClick={()=>{
                                    set_options_visible(false)
                                    set_selected_item(items[i])
                                    set_item_clicked(true)
                                }}>
                                    {items[i]}
                                </h1>
                            </div>
                    }
                return elts;
                    }
    
    const item_clicking_thing = () => {
        if(item_clicked)
            {
                Input_Bar.current.value = selected_item
                set_item_clicked(false) 
                change_function(selected_item)

                if(setChange)
                {
                    setChange(selected_item)
                }            
            }
    }


    useEffect(()=>{

        let window_click_event = window.addEventListener('click', 
            (e)=>{               
                if(!(e.target === Input_Bar.current))
                    {
                        set_options_visible(false)
                    }
            })

        return ()=>{
            window.removeEventListener("click", window_click_event)
        }
        
    },[])


    return  <div className='main_container'>
            {item_clicking_thing()}

            {

            (DropDown) ?
                <Fragment>
                        <input     
                            className='drop_down_input_bar'
                            placeholder={placeholder}

                            onClick={()=>{
                                set_options_visible(!options_visible)        
                                    }}
                            onInput={(e)=>{  
                                set_display_items(filteration(e.target.value, initial_display_items))
                                change_function(e.target.value)
                                    }}
                            ref={Input_Bar}
                            >

                        </input>

                            {
                            (options_visible) ?

                            <div className='list_items_display'>
                                        {list_display(display_items)}
                            </div>

                            :

                            <div>
                            </div>
                            }
                </Fragment>
                    :
                <Fragment>
                        <input     
                        className='drop_down_input_bar'
                        placeholder={placeholder}

                        onInput={(e)=>{  
                            set_display_items(filteration(e.target.value, initial_display_items))
                            change_function(e.target.value)
                                }}
                        ref={Input_Bar}
                        >
                    </input>

                </Fragment>
            
            }
            
            </div>
}

export default INPUT_CUSTOM;