import './LandingPage.css';

import { Link } from 'react-router-dom';

//Components
import INTRO from "../../components/Intro/Intro";
import Button from "../../components/reusable/Button/Button";

//Landing Page
const LandingPage = ( ) => 
            {
                return  <div>
                            <INTRO/>
                            <div className='open_app_button'>
                                <Link className="link" to="/inputs">
                                    <Button displayText={"Open App"} idTitle={'landingPage'}/>
                                </Link>
                            </div>
                        </div>
            }

export default LandingPage;