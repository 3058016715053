import './Intro.css'

//React 
import {useState, useEffect} from 'react';

//Components
import Chess from './3Ds/Chess';

const INTRO = () => {
    
    const [animationStage, setAnimationStage] = useState(0);
    const [scrollPercentage, setScrollPercentage] = useState(0);
    
    useEffect(()=>{
        const total_height = window.innerHeight * 2;
        
        const scroll_listener = window.addEventListener('scroll', ()=>{
                                    const scroll_length = window.scrollY.valueOf();

                                    const percentage = (scroll_length/total_height) * 100;
                                    
                                    console.log('scroll length is ' + scroll_length)
                                    
                                    setScrollPercentage(percentage)
                                })

        return window.removeEventListener('scroll', scroll_listener)
    }, [])

    useEffect(()=>{
        if(scrollPercentage<33)
        {
            setAnimationStage(0)
        }
        else if(scrollPercentage<66)
        {
            setAnimationStage(1)
        }
        else
        {
            setAnimationStage(2)
        }
    }
    ,[scrollPercentage])

    return  <div className='intro_container'>
                <div className='content'>
                    {(animationStage===0) ?
                        <div className='deep_blue'>
                            <h1>1997</h1>
                            <h1>Deep Blue Beats Kasparov</h1>
                        </div>
                        :
                            (animationStage===1) ?
                                <div className='alpha_go'>
                                    <h1>2014</h1>
                                    <h1>Alpha Go Beats Lee Sedol</h1>
                                </div>
                                    :
                                <div className='j'>    
                                    <h1>2023</h1>
                                    <h1>J plays Real Estate|Monopoly</h1>
                                </div>
                                }
                    <Chess animationStage={animationStage} setAnimationStage={setAnimationStage}/>
                </div>
            </div>

        }

export default INTRO;