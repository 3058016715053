import "./Chess.css";

//React Three Fiber Imports
import { Canvas } from "@react-three/fiber";
import { useLoader, useFrame } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Suspense, Fragment  } from "react";

//Scene Imports
import SceneURL from './chess.glb';

//React Imports
import { useState, useEffect, useRef } from "react";

//Three Imports 
import * as Three from 'three'


const ANIMATIONSTAGES = 3;

const Model = ({animationStage, markerRef}) => {
  
  const gltf = useLoader(GLTFLoader, SceneURL);  
  

  const vec = new Three.Vector3()

  useFrame(state=>{
    //Stages

    if(animationStage===0){
      state.camera.position.lerp(vec.set(0.9, 0.2, 0.3), 0.1)
      state.camera.rotation.set(0.1, 1.1, 0)
      state.camera.updateProjectionMatrix()
    }
    
    else if(animationStage===1){ 
      state.camera.position.lerp(vec.set(0.9, 0.4, 0.35), 0.1)
      state.camera.rotation.set(0.7, 1.1, 0)
      state.camera.updateProjectionMatrix()
    }

    else if (animationStage===2)
    {
      state.camera.position.lerp(vec.set(-0.28, 0.1, 0), 0.1)
      state.camera.rotation.set(0, -0.8, 0)
      state.camera.updateProjectionMatrix()  
    }
  
    return null;
  })

  
  return (
    <Fragment>
      <primitive object={gltf.scene} scale={0.4} position={[0,0,0]}/>
    </Fragment>
  );
};

const Chess = ({animationStage, setAnimationStage}) => {
  
 
  const markerRef=useRef()

  return (
    <div className="chess">
      <Canvas>
        <Suspense fallback={null}>
          
          <Model ref={markerRef} animationStage={animationStage} markerRef={markerRef}/>
          <ambientLight/>
          <pointLight position={[0,0,0]}/>
        </Suspense>
      </Canvas>
    </div>
  );
}


export default Chess;