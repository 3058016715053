import './Button.css';

//Importing GSAP
import {useRef} from 'react';
import gsap from "gsap";
import { useGSAP } from '@gsap/react';

const Button = ({displayText, onClick, idTitle}) => {

    const container = useRef();
    const timeLineEnter = useRef();
    const timeLineLeave = useRef();

    useGSAP(()=>{

        timeLineEnter.current = gsap.timeline({paused: true})
                                
                                .to(`#${idTitle}button_background`, 
                                {
                                    duration: 0.5, 
                                    transformOrigin: 'top left',
                                    width: '100%',
                                    border: '2px solid black'
      
                                })

        timeLineLeave.current = gsap.timeline({paused: true})
                                .to(`#${idTitle}button_background`, 
                                {
                                    duration: 0.5, 
                                    transformOrigin: 'top left',
                                    width: '0%',
                                    border: '0px solid black'
                                })

        //Mouse Enter
        document.querySelector(`#${idTitle}button_container`)
                            .addEventListener("mouseenter", ()=>{
                                if(!timeLineEnter.current.isActive())
                                {
                                    timeLineEnter.current.play(0);
                                }
                            })

        //Mouse Leave
        document.querySelector(`#${idTitle}button_container`)
                            .addEventListener("mouseleave", ()=>{
                                if(!timeLineLeave.current.isActive())
                                {
                                    timeLineLeave.current.play(0);
                                }
                            })                            
    },
    {scope: container}
)


    return  <div ref={container} className='button_container' id={idTitle+'button_container'}>
                
                <div  className = "button_skeleton"
                    onClick={onClick}>
                </div>

                <div className='button_background' id={idTitle+'button_background'}>
                </div>
                
                <div className='button_text'>
                    {displayText}
                </div>
            </div>
}

export default Button;