import './InputsPage.css'

import {Link} from 'react-router-dom'

//Components
import Button from "../../components/reusable/Button/Button";
import CText from '../../components/reusable/ClickableText/CText';

//imports URL
import { URL } from '../../App';
import INPUT_CUSTOM from '../../components/reusable/INPUT_CUSTOM/INPUT_CUSTOM';
import { useState } from 'react';
/////////////////////////
import ErrorPage from '../ErrorPage/ErrorPage';
/////
let GPT_Report = ''
//////
const NUM_OF_QUESTIONS = 13
/////////////////////////////////
let BOROUGH = "N/A"
let NEIGHBORHOOD = "N/A"
let BUILDING_CLASS_CATEGORY = "N/A"
let BLOCK = 0

let LOT = 0
let ZIP_CODE = 0
let RESIDENTIAL_UNITS = 0

let COMMERCIAL_UNITS = 0
let LAND_SQUARE_FEET = 0
let GROSS_SQUARE_FEET = 0

let TAX_CLASS = 0
let BUILDING_CLASS = "N/A"
let SALE_DATE = 0


////////////////////////////////

const InputsPage = ({setResponseOutput, setReportLoading}) => {

    const [questionNumber, setQuestionNumber] = useState(0);
    const [borough, setBorough] = useState('')

    const [err, setErr] = useState(false)

/////////////

  const change_borough = (value)=>{
    BOROUGH = value
    return
  }

  const change_neighborhood = (value) => {
    NEIGHBORHOOD = value
    return
  }

  const change_building_class_category = (value) => {
    BUILDING_CLASS_CATEGORY = value
    return
  }

 /////////////////////////////////////////////////////////////////////////////



  const make_request = (request_url) => {
    
    let body = [{"borough": [BOROUGH], "neighborhood": [NEIGHBORHOOD], 
              "building_class_category": [BUILDING_CLASS_CATEGORY], 
              "block": [parseInt(BLOCK)], 
              "lot": [parseInt(LOT)], 
              "zip_code": [parseInt(ZIP_CODE)], "residential_units": [parseFloat(RESIDENTIAL_UNITS)], 
              "commercial_units": [parseFloat(COMMERCIAL_UNITS)], 
              "total_units": [parseFloat(RESIDENTIAL_UNITS) + parseFloat(COMMERCIAL_UNITS)], 
              "land_square_feet": [parseFloat(LAND_SQUARE_FEET)], "gross_square_feet": [parseFloat(GROSS_SQUARE_FEET)],
              "tax_class": [parseInt(TAX_CLASS)], 
               "building_class": [BUILDING_CLASS], "sale_date": [parseInt(SALE_DATE)]}]

    body = JSON.stringify(body)

    fetch(request_url, {
        method: "POST",
        headers: {
            'Content-Type':'application/json',
            'Access-Contol-Allow-Origin': '*'
        },
        body: body
      })
      .then((res)=>res.json())
      .then(
        (res)=>{  
          let predicted_values_from_API = res.predicted_values_from_API
          GPT_Report = res.chatGPT_report
          
          predicted_values_from_API = JSON.parse(predicted_values_from_API)
          
          setResponseOutput(predicted_values_from_API[0].output_1[0])
            
        }
      )
  }
    
//////////////////////////////////////////////////////////////////////////

    const questionNumberSetter = () => {        
        switch (questionNumber){

            case 0:
                return  <div>
                            <h1> Give us some information on the Estate to generate a report. </h1>
                        </div>
            case 1:   
                return  <INPUT_CUSTOM  URL = {URL+'/borough_list'} 
                                    change_function={change_borough}
                                    Options = {{'method': 'GET'}}
                                    questionNumber={questionNumber}
                                    placeholder={"Borough"}
                                    DropDown={true}
                                    setErr = {setErr}
                                    setChange = {setBorough}
                                />
            case 2: 
                return  <INPUT_CUSTOM  URL={URL+'/neighborhood'} 
                                    change_function={change_neighborhood}
                                    Options = {{'method': 'POST', 
                                                'body': borough
                                                }}
                                    questionNumber={questionNumber}
                                    setErr={setErr}
                                    placeholder={"Neighborhood"}
                                    DropDown={true}
                                    />   
            case 3: 
                return <INPUT_CUSTOM    URL={URL+'/building_class_category'}
                                        change_function={change_building_class_category}
                                        Options = {{'method': 'GET'}}
                                        questionNumber={questionNumber}
                                        setErr = {setErr}
                                        placeholder={"Building Class Category"}
                                        DropDown={true}
                                        />

            case 4: 
                return <INPUT_CUSTOM    change_function={(value)=>{
                                            BLOCK = value
                                        }}

                                        Options = {{'method': 'GET'}}
                                        questionNumber={questionNumber}
                                        setErr = {setErr}
                                        placeholder={"Block"}
                                        DropDown={false}
                                        />
            case 5: 
                return <INPUT_CUSTOM    change_function={(value)=>{
                                            LOT = value
                                        }}  

                                        Options={{'method': 'GET'}}
                                        questionNumber={questionNumber}
                                        setErr={setErr}
                                        placeholder={"Lot"}
                                        DropDown={false}
                        />
            case 6: 
                return <INPUT_CUSTOM    change_function={(value)=>{
                                            ZIP_CODE = value
                                        }}  

                                        Options={{'method': 'GET'}}
                                        questionNumber={questionNumber}
                                        setErr={setErr}
                                        placeholder={"Zip Code"}
                                        DropDown={false}
                                />
            case 7: 
                return <INPUT_CUSTOM    change_function={(value)=>{
                                            RESIDENTIAL_UNITS = value
                                        }} 

                                        Options={{'method': 'GET'}}
                                        questionNumber={questionNumber}
                                        setErr={setErr}
                                        placeholder={"Residential Units"}
                                        DropDown={false}
                                        />
            case 8: 
                return <INPUT_CUSTOM    change_function={(value)=>{
                                            COMMERCIAL_UNITS = value
                                        }}  

                                        Options={{'method': 'GET'}}
                                        questionNumber={questionNumber}
                                        setErr ={setErr}
                                        placeholder={"Commercial Units"}
                                        DropDown={false}
                                                />
            case 9: 
                return <INPUT_CUSTOM    change_function={(value)=>{
                                            LAND_SQUARE_FEET = value
                                        }}  

                                        Options={{'method': 'GET'}}
                                        questionNumber={questionNumber}
                                        setErr={setErr}
                                        placeholder={"Land Square Feet"}
                                        DropDown={false}
                                                />
            case 10: 
                return <INPUT_CUSTOM    change_function={(value)=>{
                                            GROSS_SQUARE_FEET = value    
                                        }}  

                                        Options={{'method': 'GET'}}
                                        questionNumber={questionNumber}
                                        setErr={setErr}
                                        placeholder={"Gross Square Feet"}
                                        DropDown={false}
                                        />
            case 11: 
                return <INPUT_CUSTOM    change_function={(value)=>{
                                            TAX_CLASS = value
                                        }}  

                                        Options={{'method': 'GET'}}
                                        questionNumber={questionNumber}
                                        setErr={setErr}
                                        placeholder={"Tax Class"}
                                        DropDown={false}
                                        />

            case 12: 
                return <INPUT_CUSTOM    change_function={(value)=>{
                                            BUILDING_CLASS = value
                                        }}  

                                        Options={{'method': 'GET'}}
                                        questionNumber={questionNumber}
                                        setErr={setErr}
                                        placeholder={"Building Class"}
                                        DropDown={false}
                        />
            case 13: 
                return <INPUT_CUSTOM    change_function={(value)=>{
                                            SALE_DATE = value
                                        }}  

                                        Options={{'method': 'GET'}}
                                        questionNumber={questionNumber}
                                        setErr={setErr}
                                        placeholder={"Sale Date"}
                                        DropDown={false}
                                />
            }
        }

    return  (err) ?
                <ErrorPage></ErrorPage>
                    :
            <div className='inputs_page_container'>
                <div className='chess_themed_background_pattern'>
                    <div className='white_overlay'>
                    </div>
                </div>

                <div className='inputs_page_content_container'>

                    <div className='back_to_landing_button'>
                        <Link className='link' to='/'>
                            <Button displayText={"<"} idTitle={'backToLanding'}/>
                        </Link>
                    </div>
                    
                    <div className='question_display'>
                        {
                            questionNumberSetter()
                        }
                    </div>

                        {
                            (questionNumber === 0 )
                                        ?
                                <div className='begin_button'>
                                    <Button displayText={'Begin'}
                                            onClick = {()=>{
                                                        setQuestionNumber(1)
                                                    }}
                                            idTitle={'beginButton'}
                                    />
                                </div>
                                    :
                                <div className='question_navigations'>

                                    <CText  display_text={'Previous'} 
                                            onClick = {
                                                ()=>{
                                                    if(questionNumber>0)
                                                        {
                                                        setQuestionNumber((val)=>
                                                            {
                                                                return val - 1
                                                            })
                                                        }
                                            }}/>
                                    {
                                    (questionNumber===NUM_OF_QUESTIONS) ?
                                    
                                    <Link className='link' to="/report_page">

                                        <CText  display_text={'Submit'}
                                                onClick={()=>{
                                                    setResponseOutput(0)
                                                    setReportLoading(true)
                                                   
                                                    make_request(URL+'/v1')
                                                }}
                                        />

                                    </Link>
                                        :
                                    
                                    <CText display_text={'Next'} 
                                            onClick = {()=>{
                                                if(questionNumber<NUM_OF_QUESTIONS)
                                                    {
                                                    setQuestionNumber((val)=>
                                                        {
                                                            return val + 1
                                                        })
                                                    }
                                            }}/>
                                    }
                                </div>
                            }

                    </div>
            </div>
}

export default InputsPage;
export {GPT_Report}