import './App.css';
import { useState } from 'react';

import {Routes, Route} from 'react-router-dom';

//Components
import LandingPage from './routes/LandingPage/LandingPage';
import InputsPage from './routes/InputsPage/InputsPage';
import ReportPage from './routes/ReportPage/ReportPage';

const URL = ''

const App = () => {
 
  const [predictedPrice, setPredictedPrice] = useState(0)
  const [reportLoading, setReportLoading] = useState(true)
 
  return (
    <div className="App">

      <Routes>
        <Route path='/' element={<LandingPage/>} />
        <Route basename="/" path="/inputs" element={<InputsPage setResponseOutput={setPredictedPrice} setReportLoading={setReportLoading}/>}/>
        <Route basename="/" path="/report_page" element={<ReportPage predictedPrice={predictedPrice} loading={reportLoading} setLoading={setReportLoading}/>} />
      </Routes>        
    </div>
  );
}

export default App;
export {URL};
